import { type FC, useRef, useEffect } from 'react';
import {getContrastColor, getTextPositions} from '../../utils';
import type { Label, Category } from '../../types';
import type { TextAnnotatorProps } from "./interfaces";
import './styles.css';

const TextAnnotator: FC<TextAnnotatorProps> = ({
  text,
  labels,
  textDisplay,
  selectedCategory,
  getQuestionFromText,
  onUpdateLabels,
  scrollToAnnotation
}) => {
  const textContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    renderAnnotations();
  }, [labels, text]);

  const handleMouseUp = () => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0 && selectedCategory) {
      const range = selection.getRangeAt(0);

      if (range && range.toString().length > 0) {
        addAnnotation(range, selectedCategory);
      }
    }
  };

  const addAnnotation = (range: Range, category: Category) => {
    const startOffset = getGlobalOffset(range.startContainer, range.startOffset);
    const endOffset = getGlobalOffset(range.endContainer, range.endOffset);
    const selectedText = range.toString();

    const overlap = labels.some(label => 
      (startOffset >= label.start && startOffset < label.end) || 
      (endOffset > label.start && endOffset <= label.end) || 
      (startOffset <= label.start && endOffset >= label.end)
    );

    // if (overlap) {
    //   return;
    // }

    const annotations = labels.map((label) => {
      if (label.start !== -1) {
        return label;
      }

      const { start, end } = getTextPositions(text, label.text);

      return {
        ...label,
        start,
        end
      }
    });

    let idx = 0;

    // Mapping annotation collection
    for (let i = 0; i < annotations.length; i++) {
      if (annotations[i].start > startOffset && i === 0) {
        break;
      }

      // Searching element that have END coordinate bigger that new annotation START
      if (annotations[i].end < startOffset) {

        // Searching for first next element that have START coordinate
        for (let j = 1; j < annotations.length - i; j++) {
          if (annotations[i + j].start > endOffset) {
            idx = i;
            break;
          }

          // if next element have coordinate but START smaller that new annotation END we stop this cycle
          if (annotations[i + j].start !== -1) {
            break;
          }
        }
      }

      if (i === annotations.length - 1) {
        idx = annotations.length - 1
      }

      if (idx) {
        break;
      }
    }

    const before = [...annotations].splice(0, idx === 0 ? idx : idx + 1);
    const after = [...annotations].splice(idx === 0 ? idx : idx + 1).map(annotation => ({
      ...annotation,
      order: annotation.order + 1
    }));

    const newAnnotation: Label = {
      order: idx === 0 ? idx + 1 : idx + 2,
      labelId: `${category.labelName}-${Date.now()}`,
      label: category.labelName,
      start: startOffset,
      end: endOffset,
      color: category.color,
      text: selectedText,
    };

    const updatedAnnotations = [...before, newAnnotation, ...after];

    getQuestionFromText(newAnnotation.text, newAnnotation)
    onUpdateLabels(updatedAnnotations);
  };

  const getGlobalOffset = (node: Node, offset: number): number => {
    let globalOffset = 0;
    let current = node;

    while (current && current !== textContainerRef.current) {
      if (current.previousSibling) {
        current = current.previousSibling;
        globalOffset += (current.textContent?.length || 0);
      } else {
        current = current.parentNode;
      }
    }

    return globalOffset + offset;
  };

  const renderAnnotations = () => {
    if (textContainerRef.current) {
      textContainerRef.current.innerHTML = '';

      let currentPos = 0;

      labels.forEach((annotation) => {
        const { color, label, text: context } = annotation;
        const { start, end } = getTextPositions(text, context);

        if (start === -1) {
          return;
        }

        const sentence = text.slice(start, end);

        if (textContainerRef.current.textContent.includes(sentence)) {
          return;
        }


        if (currentPos < start) {
          const beforeText = text.slice(currentPos, start);
          const textNode = document.createTextNode(beforeText);

          textContainerRef.current?.appendChild(textNode);
        }

        const mark = document.createElement('mark');
        mark.className = 'annotation';
        mark.style.backgroundColor = color;
        mark.style.color = getContrastColor(color);
        mark.onclick = () => scrollToAnnotation(annotation);

        const span = document.createElement('span');
        span.className = 'label';
        span.style.backgroundColor = color;
        span.style.color = getContrastColor(color);
        span.innerText = label;

        const annotationTextNode = document.createTextNode(sentence);
        mark.appendChild(annotationTextNode);
        mark.appendChild(span);
        textContainerRef.current?.appendChild(mark);

        currentPos = end;
      });

      if (currentPos < text.length) {
        const remainingText = document.createTextNode(text.slice(currentPos));

        textContainerRef.current?.appendChild(remainingText);
      }
    }
  };

  return (
    <div ref={textContainerRef} style={{ whiteSpace: textDisplay }} onMouseUp={handleMouseUp}>
      {text}
    </div>
  );
};

export default TextAnnotator;
export { default as LabelSelector } from './LabelSelector';
export { default as AnnotationList } from './AnnotationList';
